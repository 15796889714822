body {
  background-color: #282c34;
  color: white;
  padding: 0px 20px 0px 20px;
}

*{
  border-width: 0px;
  padding: 0px;
  margin: 0px;
}

a {
  color: #61dafb;
}

nav{
  display:flex;
  justify-content: center;
  width: 180px;
}

nav > ul{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}


.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Welcome{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(15px + 2vmin);
  min-height: 40vh;
}

.topHeader{
  display:flex;
  justify-content: space-between;
  height: 50px;
  font-size: calc(10px + 1vmin);
}

.content{
  margin: 10px 0px 10px 0px;
}